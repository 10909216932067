<template>
  <div id="view-services">
    <v-container>
      <h1 class="mt-4">Subcategory Details</h1>
      <ServicesForm
        :initial-service="service"
        :is-view="true"
        @save="updateService"
        v-if="service.id"
      />
      <Alert
        :showAlert="saveClicked.isClicked"
        :type="saveClicked.type"
        allowDismiss
        absolute
        :text="saveClicked.message"
        :inPage="false"
        :backgroundColour="true"
      />
      <Alert
        :showAlert="isErrorMessage"
        type="error"
        allowDismiss
        :text="errorMessage"
        :backgroundColour="true"
      />
    </v-container>
  </div>
</template>
<script>
import { Alert } from '@nswdoe/doe-ui-core'
import ServicesForm from '@/views/Services/form'

export default {
  name: 'UpdateSubcategory',
  title: 'Update Subcategory - SAIS Operation UI Portal',
  components: {
    Alert,
    ServicesForm,
  },
  created() {
    this.$store
      .dispatch('moduleServices/fetchService', {
        serviceId: this.$route.params.id,
      })
      .finally(() => {
        if (this.$store.state.moduleServices.selectedService?.id) {
          this.service = this.$store.state.moduleServices.selectedService
        }
      })
  },
  data() {
    return {
      service: {},
      saveClicked: {
        isClicked: false,
        isSuccess: false,
        message: '',
      },
    }
  },
  methods: {
    async updateService(item) {
      if (item.allowedResources.length < 1) {
        this.saveClicked = {
          isClicked: true,
          type: 'error',
          isSuccess: false,
          message: 'Please select at least one SIF Object',
        }
        return
      }

      const response = await this.$store.dispatch('moduleServices/putService', item)
      this.saveClicked = {
        isClicked: true,
        type: response.isError ? 'error' : 'success',
        isSuccess: !response.isError,
        message: response.isError
          ? response.errorMessage
          : 'The changes have been saved successfully',
      }

      if (!response.isError) {
        this.$router.push('/serviceregistry/services?updated=true')
      }
    },
  },
  computed: {
    errorMessage() {
      return this.$store.state.moduleServices.errorMessage
    },
    isErrorMessage() {
      const errorMessage = this.$store.state.moduleServices.errorMessage
      return !!errorMessage && errorMessage.length > 0
    },
  },
}
</script>
